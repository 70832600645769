.sidebar {
  width: 100%;
}

.sidebar .mdc-list > li {
  font-size: 20px;
  line-height: 26px;
}

@media (min-width: 1200px) {
  .sidebar {
    max-width: 350px;
  }
}


.category-menu {
  width: 98%;
  box-sizing: border-box;
}

.sidebar .sidebar-menu {
  position: fixed;
  top: 180px;
  max-width: 35%;
}

@media (max-width: 768px) {
  .sidebar .sidebar-menu {
    top: 180px;
  }
}

@media (max-width: 693px) {
  .sidebar .sidebar-menu {
    top: 260px;
  }
}

@media (max-height: 800px) {
  .sidebar .sidebar-menu {
    position: absolute !important;
  }
}