.mdc-top-app-bar.nav-complex .mdc-top-app-bar__row {
  min-height: 125px;
  height: auto;
  flex-wrap: wrap;
  background-position-x: 30px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.mdc-top-app-bar--fixed-adjust.complex {
  padding-top: 180px;
}

.mdc-top-app-bar__title {
  opacity: 1;
  transform: opacity;
  transition-duration: 0.8s;
}

.mdc-top-app-bar__section {
  padding-top: 4px !important;
}

.mdc-top-app-bar__section.mdc-top-app-bar__section--align-start {
  padding-left: 180px;
}

.mdc-top-app-bar__section.mdc-top-app-bar__section--align-start > .mdc-top-app-bar__title > h4 {
  margin: 10px;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mdc-top-app-bar.nav-complex .mdc-top-app-bar__section.mdc-top-app-bar__section--align-start > .mdc-top-app-bar__title {
  width: -moz-available;
  width: available;
}

.mdc-top-app-bar__section.mdc-top-app-bar__section--align-end {
  padding-right: 155px;
}

@media (max-width: 1012px) {
  .search-button {
    background-color: rgba(52,58,64,0.4);
    border-radius: 25px;
  }
}

@media (max-width: 883px) {
  .mdc-top-app-bar.nav-complex .mdc-top-app-bar__row {
    min-height: 140px;
  }
}

@media (max-width: 767px) {
  .mdc-top-app-bar__section.mdc-top-app-bar__section--align-start {
    padding-left: 0px;
    align-items: flex-start;
  }

  .mdc-top-app-bar.nav-complex .mdc-top-app-bar__section.mdc-top-app-bar__section--align-start {
    width: 400px;
    max-width: 100%;
  }

  .mdc-top-app-bar.nav-complex .mdc-top-app-bar__section.mdc-top-app-bar__section--align-start > form {
    height: 42px;
  }

  .mdc-top-app-bar__section.mdc-top-app-bar__section--align-end {
    align-items: center;
    padding-right: 4px;
    padding-bottom: 0;
  }
}

@media (max-width: 644px) {
  .mdc-top-app-bar.nav-complex .mdc-top-app-bar__row {
    background-position-y: 45px;
    background-position-x: center;
    background-size: auto 95px;
  }
}

@media (max-width: 693px) {
  .mdc-top-app-bar--fixed-adjust.complex {
    padding-top: 280px;
  }
}
