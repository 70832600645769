ul.product-list {
  max-width: 600px;
  margin: 0 auto;
}

.product-list .mdc-list-item {
  height: auto;
  min-height: 75px;
  max-height: 120px;
  border-bottom: 1px solid rgb(224, 223, 223);
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.product-list.mdc-list {
  padding: 0 !important;
}

.product {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.product.product-inline {
  flex-direction: row;
  align-items: center;
}

.product__info {
  height: 100%;
  width: 75%;
}

.product__preview {
  width: 25%;
  max-width: 120px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product__preview > img, .product__preview > div > img {
  height: 72px;
  width: 72px;
  border-radius: 0.375rem !important;
}

.product .product__name-and-price, .product .product__description-and-action {
  display: flex;
  justify-content: space-between;
}

.product__description {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #9fa6ad;
}

.product__description, .product__action {
  max-height: 80px;
}

.product__price {
  text-align: right;
  width: 75px;
  min-width: 55px;
}

.product__action {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*---------text-with-bg-image----------*/

.mdc-card.text-with-bg-image {
  width: 100%;
  max-width: 412px;
  margin: 0 auto;
}

.mdc-card.text-with-bg-image .body {
  position: absolute;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.mdc-card.text-with-bg-image, .text-with-bg-image .mdc-card__primary-action {
  border-radius: 0;
}